import React, { FC, useContext, Fragment } from 'react';
import { GlobalContext } from '@src/provider';
import { NewestArticle } from '@components';
import styled from '@emotion/styled';

const ComponentList = { NewestArticle: NewestArticle };

const Divider = styled.div<{ isLast: boolean }>`
    margin-bottom: ${(props) => (props.isLast ? null : props.theme.spacing(7))}px;
`;

const LandingPageComponents: FC = () => {
    const { state } = useContext(GlobalContext);
    const { landingPageTemplateData } = state;
    const { content } = landingPageTemplateData;

    const data = content?.filter((item) => item.enabled)?.sort((a, b) => a.order - b.order);
    return (
        <Fragment>
            {data?.map((item, index) => {
                const Component = ComponentList[item.name];
                return (
                    <Fragment key={item.name + index}>
                        <Component {...item.props} />
                        <Divider isLast={index === data.length - 1} />
                    </Fragment>
                );
            })}
        </Fragment>
    );
};

export default LandingPageComponents;
