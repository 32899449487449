/** @jsx jsx */
import { FC } from 'react';
import { css, jsx } from '@emotion/react';
import Grid from '@material-ui/core/Grid';
import { Layout, Banner } from '@components';
import { default as LandingPageComponents } from './components';

const HomeTemplate: FC = () => {
    return (
        <Layout isLandingPage>
            <Banner />
            <Grid
                container
                spacing={2}
                css={css`
                    margin-top: calc(100vh);
                `}
            >
                <LandingPageComponents />
            </Grid>
        </Layout>
    );
};
export default HomeTemplate;
